const num34 = {
  prev: null,
  now: {
    id: 34,
    createdAt: "2024-11-08T08:39:32.158Z",
    updatedAt: "2024-11-08T14:03:26.400Z",
    serverFileName: "79e35e41-3d98-4a0e-ab62-3a7157c83da1.jpg",
    originFileName: "/assets/imgs/lumir-news/워크샵_상단메인.jpg",
    fileUrl: "/assets/imgs/lumir-news/워크샵_상단메인.jpg",
    title: "2024 한국전자파학회 하계학술대회 참가기",
    category: "LUMIR TOPIC",
    content: '<p><img src="/assets/imgs/lumir-news/20241106_워크샵.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 33,
    createdAt: "2024-10-28T08:39:32.158Z",
    updatedAt: "2024-10-28T14:03:26.400Z",
    serverFileName: "79e35e41-3d98-4a0e-ab62-3a7157c83da1.jpg",
    originFileName: "/assets/imgs/lumir-news/AEROTEC_상단메인.jpg",
    fileUrl: "/assets/imgs/lumir-news/AEROTEC_상단메인.jpg",
    title: "제1회 국제항공우주기술대전 AEROTEC 2024 이야기",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="/assets/imgs/lumir-news/20241105_AEROTEC_2024.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num33 = {
  prev: num34.now,
  now: {
    id: 33,
    createdAt: "2024-10-28T08:39:32.158Z",
    updatedAt: "2024-10-28T14:03:26.400Z",
    serverFileName: "79e35e41-3d98-4a0e-ab62-3a7157c83da1.jpg",
    originFileName: "/assets/imgs/lumir-news/AEROTEC_상단메인.jpg",
    fileUrl: "/assets/imgs/lumir-news/AEROTEC_상단메인.jpg",
    title: "제1회 국제항공우주기술대전 AEROTEC 2024 이야기",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="/assets/imgs/lumir-news/20241105_AEROTEC_2024.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 32,
    createdAt: "2024-10-21T08:39:32.158Z",
    updatedAt: "2024-10-21T14:03:26.400Z",
    serverFileName: "79e35e41-3d98-4a0e-ab62-3a7157c83da1.jpg",
    originFileName: "/assets/imgs/lumir-news/상장기념식_상단메인.jpg",
    fileUrl: "/assets/imgs/lumir-news/상장기념식_상단메인.jpg",
    title: "루미르 코스닥 상장기념식 이야기",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="/assets/imgs/lumir-news/20241101_상장기념식.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num32 = {
  prev: num33.now,
  now: {
    id: 32,
    createdAt: "2024-10-21T08:39:32.158Z",
    updatedAt: "2024-10-21T14:03:26.400Z",
    serverFileName: "79e35e41-3d98-4a0e-ab62-3a7157c83da1.jpg",
    originFileName: "/assets/imgs/lumir-news/상장기념식_상단메인.jpg",
    fileUrl: "/assets/imgs/lumir-news/상장기념식_상단메인.jpg",
    title: "루미르 코스닥 상장기념식 이야기",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="/assets/imgs/lumir-news/20241101_상장기념식.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 31,
    createdAt: "2024-08-29T08:39:32.158Z",
    updatedAt: "2024-08-29T14:03:26.400Z",
    serverFileName: "79e35e41-3d98-4a0e-ab62-3a7157c83da1.jpg",
    originFileName: "상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/79e35e41-3d98-4a0e-ab62-3a7157c83da1.jpg",
    title: "2024 한국전자파학회 하계학술대회 참가기",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/7f191185-2601-4fc6-b675-ffb8575f1d3e.jpeg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num31 = {
  prev: num32.now,
  now: {
    id: 31,
    createdAt: "2024-08-29T08:39:32.158Z",
    updatedAt: "2024-08-29T14:03:26.400Z",
    serverFileName: "79e35e41-3d98-4a0e-ab62-3a7157c83da1.jpg",
    originFileName: "상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/79e35e41-3d98-4a0e-ab62-3a7157c83da1.jpg",
    title: "2024 한국전자파학회 하계학술대회 참가기",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/7f191185-2601-4fc6-b675-ffb8575f1d3e.jpeg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 30,
    createdAt: "2024-07-24T17:39:06.850Z",
    updatedAt: "2024-07-24T17:44:42.620Z",
    serverFileName: "2c0a1928-fc5f-4eef-98e4-a38062073e58.jpg",
    originFileName: "상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2c0a1928-fc5f-4eef-98e4-a38062073e58.jpg",
    title: "루미르 2024년 우수기업연구소 선정",
    category: "AWARDS",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/4b6a79e1-6c22-4ae9-8c52-6f93f9a7e6b2.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num30 = {
  prev: {
    id: 31,
    createdAt: "2024-08-29T08:39:32.158Z",
    updatedAt: "2024-08-29T14:03:26.400Z",
    serverFileName: "79e35e41-3d98-4a0e-ab62-3a7157c83da1.jpg",
    originFileName: "상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/79e35e41-3d98-4a0e-ab62-3a7157c83da1.jpg",
    title: "2024 한국전자파학회 하계학술대회 참가기",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/7f191185-2601-4fc6-b675-ffb8575f1d3e.jpeg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 30,
    createdAt: "2024-07-24T17:39:06.850Z",
    updatedAt: "2024-07-24T17:44:42.620Z",
    serverFileName: "2c0a1928-fc5f-4eef-98e4-a38062073e58.jpg",
    originFileName: "상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2c0a1928-fc5f-4eef-98e4-a38062073e58.jpg",
    title: "루미르 2024년 우수기업연구소 선정",
    category: "AWARDS",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/4b6a79e1-6c22-4ae9-8c52-6f93f9a7e6b2.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 20,
    createdAt: "2024-04-23T11:28:55.364Z",
    updatedAt: "2024-04-23T11:30:12.011Z",
    serverFileName: "066b50b6-50b8-4cfe-afd4-1f11dc015520.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/066b50b6-50b8-4cfe-afd4-1f11dc015520.jpg",
    title: "루미르, 1,200평 규모 신사옥 착공식 이야기",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/4f80aa41-26d0-4daf-a8b4-430a23359bc9.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num20 = {
  prev: {
    id: 30,
    createdAt: "2024-07-24T17:39:06.850Z",
    updatedAt: "2024-07-24T17:44:42.620Z",
    serverFileName: "2c0a1928-fc5f-4eef-98e4-a38062073e58.jpg",
    originFileName: "상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2c0a1928-fc5f-4eef-98e4-a38062073e58.jpg",
    title: "루미르 2024년 우수기업연구소 선정",
    category: "AWARDS",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/4b6a79e1-6c22-4ae9-8c52-6f93f9a7e6b2.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 20,
    createdAt: "2024-04-23T11:28:55.364Z",
    updatedAt: "2024-04-23T11:30:12.011Z",
    serverFileName: "066b50b6-50b8-4cfe-afd4-1f11dc015520.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/066b50b6-50b8-4cfe-afd4-1f11dc015520.jpg",
    title: "루미르, 1,200평 규모 신사옥 착공식 이야기",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/4f80aa41-26d0-4daf-a8b4-430a23359bc9.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 19,
    createdAt: "2024-04-23T11:21:32.475Z",
    updatedAt: "2024-04-23T11:29:44.721Z",
    serverFileName: "a4db8abe-a5a3-4231-a089-df84903bfc58.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a4db8abe-a5a3-4231-a089-df84903bfc58.jpg",
    title: "2024년 루미르 승급심사",
    category: "LUMIR PEOPLE",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a76ef381-f8fb-421b-93c6-f18264c6e5a4.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num19 = {
  prev: {
    id: 20,
    createdAt: "2024-04-23T11:28:55.364Z",
    updatedAt: "2024-04-23T11:30:12.011Z",
    serverFileName: "066b50b6-50b8-4cfe-afd4-1f11dc015520.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/066b50b6-50b8-4cfe-afd4-1f11dc015520.jpg",
    title: "루미르, 1,200평 규모 신사옥 착공식 이야기",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/4f80aa41-26d0-4daf-a8b4-430a23359bc9.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 19,
    createdAt: "2024-04-23T11:21:32.475Z",
    updatedAt: "2024-04-23T11:29:44.721Z",
    serverFileName: "a4db8abe-a5a3-4231-a089-df84903bfc58.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a4db8abe-a5a3-4231-a089-df84903bfc58.jpg",
    title: "2024년 루미르 승급심사",
    category: "LUMIR PEOPLE",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a76ef381-f8fb-421b-93c6-f18264c6e5a4.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 18,
    createdAt: "2024-04-23T11:17:43.364Z",
    updatedAt: "2024-04-23T11:17:43.364Z",
    serverFileName: "c47deeeb-48ce-46ea-88a8-3287e54cd671.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/c47deeeb-48ce-46ea-88a8-3287e54cd671.jpg",
    title: "2024년 루미르 시무식",
    category: "CEREMONY",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/e5e55416-a6ec-4c91-a3f2-9683e8144767.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num18 = {
  prev: {
    id: 19,
    createdAt: "2024-04-23T11:21:32.475Z",
    updatedAt: "2024-04-23T11:29:44.721Z",
    serverFileName: "a4db8abe-a5a3-4231-a089-df84903bfc58.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a4db8abe-a5a3-4231-a089-df84903bfc58.jpg",
    title: "2024년 루미르 승급심사",
    category: "LUMIR PEOPLE",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a76ef381-f8fb-421b-93c6-f18264c6e5a4.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 18,
    createdAt: "2024-04-23T11:17:43.364Z",
    updatedAt: "2024-04-23T11:17:43.364Z",
    serverFileName: "c47deeeb-48ce-46ea-88a8-3287e54cd671.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/c47deeeb-48ce-46ea-88a8-3287e54cd671.jpg",
    title: "2024년 루미르 시무식",
    category: "CEREMONY",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/e5e55416-a6ec-4c91-a3f2-9683e8144767.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 17,
    createdAt: "2024-04-23T11:07:11.468Z",
    updatedAt: "2024-04-23T11:15:09.598Z",
    serverFileName: "1a3d23c6-d760-441f-90ba-0d0154f458b6.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/1a3d23c6-d760-441f-90ba-0d0154f458b6.jpg",
    title: "2023 대한민국 ICT 대상 디지털부문 혁신기술 장관표창 수상",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/aec33dd9-6a9c-4824-9b58-253cccfccce0.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num17 = {
  prev: {
    id: 18,
    createdAt: "2024-04-23T11:17:43.364Z",
    updatedAt: "2024-04-23T11:17:43.364Z",
    serverFileName: "c47deeeb-48ce-46ea-88a8-3287e54cd671.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/c47deeeb-48ce-46ea-88a8-3287e54cd671.jpg",
    title: "2024년 루미르 시무식",
    category: "CEREMONY",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/e5e55416-a6ec-4c91-a3f2-9683e8144767.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 17,
    createdAt: "2024-04-23T11:07:11.468Z",
    updatedAt: "2024-04-23T11:15:09.598Z",
    serverFileName: "1a3d23c6-d760-441f-90ba-0d0154f458b6.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/1a3d23c6-d760-441f-90ba-0d0154f458b6.jpg",
    title: "2023 대한민국 ICT 대상 디지털부문 혁신기술 장관표창 수상",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/aec33dd9-6a9c-4824-9b58-253cccfccce0.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 16,
    createdAt: "2024-04-23T11:02:10.069Z",
    updatedAt: "2024-04-23T11:02:10.069Z",
    serverFileName: "5041a5d0-b69d-429b-bf4e-c45b4ebf879f.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/5041a5d0-b69d-429b-bf4e-c45b4ebf879f.jpg",
    title: "한-미 우주산업 심포지엄 기자간담회",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/54a00fa3-e5b1-461a-9a3a-3512984b1af5.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num16 = {
  prev: {
    id: 17,
    createdAt: "2024-04-23T11:07:11.468Z",
    updatedAt: "2024-04-23T11:15:09.598Z",
    serverFileName: "1a3d23c6-d760-441f-90ba-0d0154f458b6.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/1a3d23c6-d760-441f-90ba-0d0154f458b6.jpg",
    title: "2023 대한민국 ICT 대상 디지털부문 혁신기술 장관표창 수상",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/aec33dd9-6a9c-4824-9b58-253cccfccce0.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 16,
    createdAt: "2024-04-23T11:02:10.069Z",
    updatedAt: "2024-04-23T11:02:10.069Z",
    serverFileName: "5041a5d0-b69d-429b-bf4e-c45b4ebf879f.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/5041a5d0-b69d-429b-bf4e-c45b4ebf879f.jpg",
    title: "한-미 우주산업 심포지엄 기자간담회",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/54a00fa3-e5b1-461a-9a3a-3512984b1af5.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 15,
    createdAt: "2024-04-23T10:47:40.384Z",
    updatedAt: "2024-04-23T10:47:40.384Z",
    serverFileName: "af084f75-d22a-4287-9f18-39439298e64d.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/af084f75-d22a-4287-9f18-39439298e64d.jpg",
    title: "루미르, 제 2기 방산혁신기업 100 프로젝트 선정",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/11ddc51b-35dc-4040-b6c2-9f39c944ff35.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num15 = {
  prev: {
    id: 16,
    createdAt: "2024-04-23T11:02:10.069Z",
    updatedAt: "2024-04-23T11:02:10.069Z",
    serverFileName: "5041a5d0-b69d-429b-bf4e-c45b4ebf879f.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/5041a5d0-b69d-429b-bf4e-c45b4ebf879f.jpg",
    title: "한-미 우주산업 심포지엄 기자간담회",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/54a00fa3-e5b1-461a-9a3a-3512984b1af5.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 15,
    createdAt: "2024-04-23T10:47:40.384Z",
    updatedAt: "2024-04-23T10:47:40.384Z",
    serverFileName: "af084f75-d22a-4287-9f18-39439298e64d.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/af084f75-d22a-4287-9f18-39439298e64d.jpg",
    title: "루미르, 제 2기 방산혁신기업 100 프로젝트 선정",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/11ddc51b-35dc-4040-b6c2-9f39c944ff35.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 14,
    createdAt: "2024-04-23T10:35:37.703Z",
    updatedAt: "2024-04-23T10:37:57.862Z",
    serverFileName: "2fe836a9-ad56-437b-a98b-5b0c11ac9b9b.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2fe836a9-ad56-437b-a98b-5b0c11ac9b9b.jpg",
    title: "서울 국제 항공우주 및 방위산업 전시회, SEOUL ADEX 2023",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/496869b5-803c-483d-a744-5e140a66dc6f.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num14 = {
  prev: {
    id: 15,
    createdAt: "2024-04-23T10:47:40.384Z",
    updatedAt: "2024-04-23T10:47:40.384Z",
    serverFileName: "af084f75-d22a-4287-9f18-39439298e64d.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/af084f75-d22a-4287-9f18-39439298e64d.jpg",
    title: "루미르, 제 2기 방산혁신기업 100 프로젝트 선정",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/11ddc51b-35dc-4040-b6c2-9f39c944ff35.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 14,
    createdAt: "2024-04-23T10:35:37.703Z",
    updatedAt: "2024-04-23T10:37:57.862Z",
    serverFileName: "2fe836a9-ad56-437b-a98b-5b0c11ac9b9b.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2fe836a9-ad56-437b-a98b-5b0c11ac9b9b.jpg",
    title: "서울 국제 항공우주 및 방위산업 전시회, SEOUL ADEX 2023",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/496869b5-803c-483d-a744-5e140a66dc6f.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 13,
    createdAt: "2024-04-23T10:30:26.817Z",
    updatedAt: "2024-04-23T10:30:26.817Z",
    serverFileName: "71ad97c8-4854-4eb7-ba06-02cec5ac633d.jpg",
    originFileName: "ㅇㅇ상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/71ad97c8-4854-4eb7-ba06-02cec5ac633d.jpg",
    title: "아시아 최대 규모 글로벌 스타트업 페어, NEXTRISE 2023 서울",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/066c126e-1d40-457a-9fb8-22b8e152a3b7.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num13 = {
  prev: {
    id: 14,
    createdAt: "2024-04-23T10:35:37.703Z",
    updatedAt: "2024-04-23T10:37:57.862Z",
    serverFileName: "2fe836a9-ad56-437b-a98b-5b0c11ac9b9b.jpg",
    originFileName: "1400상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/2fe836a9-ad56-437b-a98b-5b0c11ac9b9b.jpg",
    title: "서울 국제 항공우주 및 방위산업 전시회, SEOUL ADEX 2023",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/496869b5-803c-483d-a744-5e140a66dc6f.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 13,
    createdAt: "2024-04-23T10:30:26.817Z",
    updatedAt: "2024-04-23T10:30:26.817Z",
    serverFileName: "71ad97c8-4854-4eb7-ba06-02cec5ac633d.jpg",
    originFileName: "ㅇㅇ상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/71ad97c8-4854-4eb7-ba06-02cec5ac633d.jpg",
    title: "아시아 최대 규모 글로벌 스타트업 페어, NEXTRISE 2023 서울",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/066c126e-1d40-457a-9fb8-22b8e152a3b7.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 12,
    createdAt: "2024-04-23T10:27:49.572Z",
    updatedAt: "2024-04-23T10:27:49.572Z",
    serverFileName: "a7874ced-9a23-49c8-b5f6-d31d7aa49248.jpg",
    originFileName: "ㅇㅇ.상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a7874ced-9a23-49c8-b5f6-d31d7aa49248.jpg",
    title: "누리호 3차 LUMIR-T1 성공 이야기",
    category: "LUMIR PEOPLE",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/e7bb1ace-9d1d-4504-a095-2604b42e4770.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num12 = {
  prev: {
    id: 13,
    createdAt: "2024-04-23T10:30:26.817Z",
    updatedAt: "2024-04-23T10:30:26.817Z",
    serverFileName: "71ad97c8-4854-4eb7-ba06-02cec5ac633d.jpg",
    originFileName: "ㅇㅇ상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/71ad97c8-4854-4eb7-ba06-02cec5ac633d.jpg",
    title: "아시아 최대 규모 글로벌 스타트업 페어, NEXTRISE 2023 서울",
    category: "LUMIR TOPIC",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/066c126e-1d40-457a-9fb8-22b8e152a3b7.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 12,
    createdAt: "2024-04-23T10:27:49.572Z",
    updatedAt: "2024-04-23T10:27:49.572Z",
    serverFileName: "a7874ced-9a23-49c8-b5f6-d31d7aa49248.jpg",
    originFileName: "ㅇㅇ.상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a7874ced-9a23-49c8-b5f6-d31d7aa49248.jpg",
    title: "누리호 3차 LUMIR-T1 성공 이야기",
    category: "LUMIR PEOPLE",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/e7bb1ace-9d1d-4504-a095-2604b42e4770.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 11,
    createdAt: "2024-04-23T10:16:39.705Z",
    updatedAt: "2024-04-23T10:24:35.207Z",
    serverFileName: "44ead124-3897-4932-a238-d5a9bfffe370.jpg",
    originFileName: "20230523_상단메인템플릿.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/44ead124-3897-4932-a238-d5a9bfffe370.jpg",
    title: "한국우주기술진흥협회 취업박람회 참가",
    category: "LUMIR PEOPLE",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/3654da76-a6c3-49c0-a502-f70a786aaac7.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num11 = {
  prev: {
    id: 12,
    createdAt: "2024-04-23T10:27:49.572Z",
    updatedAt: "2024-04-23T10:27:49.572Z",
    serverFileName: "a7874ced-9a23-49c8-b5f6-d31d7aa49248.jpg",
    originFileName: "ㅇㅇ.상단메인템플릿_1920x540.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/a7874ced-9a23-49c8-b5f6-d31d7aa49248.jpg",
    title: "누리호 3차 LUMIR-T1 성공 이야기",
    category: "LUMIR PEOPLE",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/e7bb1ace-9d1d-4504-a095-2604b42e4770.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 11,
    createdAt: "2024-04-23T10:16:39.705Z",
    updatedAt: "2024-04-23T10:24:35.207Z",
    serverFileName: "44ead124-3897-4932-a238-d5a9bfffe370.jpg",
    originFileName: "20230523_상단메인템플릿.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/44ead124-3897-4932-a238-d5a9bfffe370.jpg",
    title: "한국우주기술진흥협회 취업박람회 참가",
    category: "LUMIR PEOPLE",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/3654da76-a6c3-49c0-a502-f70a786aaac7.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 10,
    createdAt: "2024-04-23T10:11:45.851Z",
    updatedAt: "2024-04-23T10:23:38.422Z",
    serverFileName: "90be6059-e42e-4b9a-9631-0cb26b9de0b4.jpg",
    originFileName: "20230512_상단메인템플릿.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/90be6059-e42e-4b9a-9631-0cb26b9de0b4.jpg",
    title: "차세대중형위성 5호 탑재체 전체 개발 수주 기념식",
    category: "CEREMONY",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/131f511c-5d70-4153-ac09-e9a6e6323fbf.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num10 = {
  prev: {
    id: 11,
    createdAt: "2024-04-23T10:16:39.705Z",
    updatedAt: "2024-04-23T10:24:35.207Z",
    serverFileName: "44ead124-3897-4932-a238-d5a9bfffe370.jpg",
    originFileName: "20230523_상단메인템플릿.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/44ead124-3897-4932-a238-d5a9bfffe370.jpg",
    title: "한국우주기술진흥협회 취업박람회 참가",
    category: "LUMIR PEOPLE",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/3654da76-a6c3-49c0-a502-f70a786aaac7.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 10,
    createdAt: "2024-04-23T10:11:45.851Z",
    updatedAt: "2024-04-23T10:23:38.422Z",
    serverFileName: "90be6059-e42e-4b9a-9631-0cb26b9de0b4.jpg",
    originFileName: "20230512_상단메인템플릿.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/90be6059-e42e-4b9a-9631-0cb26b9de0b4.jpg",
    title: "차세대중형위성 5호 탑재체 전체 개발 수주 기념식",
    category: "CEREMONY",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/131f511c-5d70-4153-ac09-e9a6e6323fbf.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 9,
    createdAt: "2024-04-23T10:08:37.829Z",
    updatedAt: "2024-04-23T10:19:55.344Z",
    serverFileName: "9ff221b9-b8d8-4072-bb9b-06ff4fedd52a.jpg",
    originFileName: "20230105_상단메인템플릿.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/9ff221b9-b8d8-4072-bb9b-06ff4fedd52a.jpg",
    title: "기업부설연구소 R&D역량강화 지원사업 주관기간 워크숍",
    category: "LUMIR PEOPLE",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/0bd256e1-42ee-4cd1-ba0f-39cb1ebc8bbd.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num9 = {
  prev: {
    id: 10,
    createdAt: "2024-04-23T10:11:45.851Z",
    updatedAt: "2024-04-23T10:23:38.422Z",
    serverFileName: "90be6059-e42e-4b9a-9631-0cb26b9de0b4.jpg",
    originFileName: "20230512_상단메인템플릿.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/90be6059-e42e-4b9a-9631-0cb26b9de0b4.jpg",
    title: "차세대중형위성 5호 탑재체 전체 개발 수주 기념식",
    category: "CEREMONY",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/131f511c-5d70-4153-ac09-e9a6e6323fbf.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 9,
    createdAt: "2024-04-23T10:08:37.829Z",
    updatedAt: "2024-04-23T10:19:55.344Z",
    serverFileName: "9ff221b9-b8d8-4072-bb9b-06ff4fedd52a.jpg",
    originFileName: "20230105_상단메인템플릿.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/9ff221b9-b8d8-4072-bb9b-06ff4fedd52a.jpg",
    title: "기업부설연구소 R&D역량강화 지원사업 주관기간 워크숍",
    category: "LUMIR PEOPLE",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/0bd256e1-42ee-4cd1-ba0f-39cb1ebc8bbd.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: {
    id: 8,
    createdAt: "2024-04-23T10:00:34.278Z",
    updatedAt: "2024-04-23T10:19:06.270Z",
    serverFileName: "add7a553-b131-4483-84e0-419edcf0abf7.jpg",
    originFileName: "20230102_상단메인템플릿.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/add7a553-b131-4483-84e0-419edcf0abf7.jpg",
    title: "2023년 루미르 시무식",
    category: "CEREMONY",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/1a34ffa5-aa0b-4d61-b266-c3637e1e3f6a.jpg"></p>',
    exposure: true,
    language: "KR",
  },
};

const num8 = {
  prev: {
    id: 9,
    createdAt: "2024-04-23T10:08:37.829Z",
    updatedAt: "2024-04-23T10:19:55.344Z",
    serverFileName: "9ff221b9-b8d8-4072-bb9b-06ff4fedd52a.jpg",
    originFileName: "20230105_상단메인템플릿.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/9ff221b9-b8d8-4072-bb9b-06ff4fedd52a.jpg",
    title: "기업부설연구소 R&D역량강화 지원사업 주관기간 워크숍",
    category: "LUMIR PEOPLE",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/0bd256e1-42ee-4cd1-ba0f-39cb1ebc8bbd.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  now: {
    id: 8,
    createdAt: "2024-04-23T10:00:34.278Z",
    updatedAt: "2024-04-23T10:19:06.270Z",
    serverFileName: "add7a553-b131-4483-84e0-419edcf0abf7.jpg",
    originFileName: "20230102_상단메인템플릿.jpg",
    fileUrl:
      "https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/add7a553-b131-4483-84e0-419edcf0abf7.jpg",
    title: "2023년 루미르 시무식",
    category: "CEREMONY",
    content:
      '<p><img src="https://lumir-media.s3.ap-northeast-2.amazonaws.com/board/1a34ffa5-aa0b-4d61-b266-c3637e1e3f6a.jpg"></p>',
    exposure: true,
    language: "KR",
  },
  next: null,
};

export const getNewsDetailData = (id) => {
  switch (id) {
    case "34":
      return num34;
    case "33":
      return num33;
    case "32":
      return num32;
    case "31":
      return num31;
    case "30":
      return num30;
    case "20":
      return num20;
    case "19":
      return num19;
    case "18":
      return num18;
    case "17":
      return num17;
    case "16":
      return num16;
    case "15":
      return num15;
    case "14":
      return num14;
    case "13":
      return num13;
    case "12":
      return num12;
    case "11":
      return num11;
    case "10":
      return num10;
    case "9":
      return num9;
    case "8":
      return num8;
    default:
      return null;
  }
};
