import React from "react";
import MainSection from "../../components/templates/mainSection";
import MiddleNavBar from "./../../components/templates/middleNavBar";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../../components/atoms/text/pretendardText";
import DepartmentContact from "../../components/templates/departmentContact";
import useDisplay from "../../hooks/useDisplay";
import { mainBannerLang } from "../../lang/lang";
import { langState } from "../../store/langState";
import { useRecoilValue } from "recoil";
import { directionLang } from "../../lang/CompanyIntroduction/direction";

const Direction = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { pathname } = useLocation();
  const langValue = useRecoilValue(langState);

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/direction.png"
            : "/assets/imgs/main-section/direction.png"
        }
        title={mainBannerLang.directions?.title[langValue]}
        content={mainBannerLang.directions?.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />
      <PageWrapper>
        <SectionWrapper
          $isMobile={isMobile}
          $isTablet={isTablet}
          style={{
            gap: "40px",
            flexDirection: isMobile ? "column" : "row",
            padding: isMobile ? "80px 0 0 0" : "",
          }}
        >
          <div
            style={{
              minWidth: "max-content",
              width: isMobile ? "100%" : "",
              padding: isMobile ? "0 20px" : "",
            }}
          >
            <Title
              $isMobile={isMobile}
              $isTablet={isTablet}
              style={{ marginBottom: "30px" }}
            >
              {directionLang.direction?.title[langValue]}
            </Title>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: isMobile ? "20px" : "26px",
              }}
            >
              <InfoWrapper $isMobile={isMobile} $isTablet={isTablet}>
                <InfoTitle $isMobile={isMobile} $isTablet={isTablet}>
                  ADDRESS
                </InfoTitle>
                <InfoContent $isMobile={isMobile} $isTablet={isTablet}>
                  {directionLang.direction?.address[langValue]}
                </InfoContent>
              </InfoWrapper>
              <HorizontalLine />
              <InfoWrapper $isMobile={isMobile} $isTablet={isTablet}>
                <InfoTitle $isMobile={isMobile} $isTablet={isTablet}>
                  TEL
                </InfoTitle>
                <InfoContent $isMobile={isMobile} $isTablet={isTablet}>
                  Phone 031-525-3800
                </InfoContent>
              </InfoWrapper>
              <HorizontalLine />
              <InfoWrapper $isMobile={isMobile} $isTablet={isTablet}>
                <InfoTitle $isMobile={isMobile} $isTablet={isTablet}>
                  FAX
                </InfoTitle>
                <InfoContent $isMobile={isMobile} $isTablet={isTablet}>
                  031-525-3801
                </InfoContent>
              </InfoWrapper>
            </div>
          </div>
          <div>
            <img
              src={
                langValue === "KR"
                  ? "/assets/imgs/introduce/directions/map_kr.webp"
                  : "/assets/imgs/introduce/directions/map.png"
              }
              alt=""
              style={{
                maxWidth: "800px",
                width: isMobile || isTablet ? "100%" : "",
              }}
            />
          </div>
        </SectionWrapper>
        <SectionWrapper
          $isMobile={isMobile}
          style={{
            maxWidth: "100%",
            backgroundColor: "#f1f1f1",
            flexDirection: "column",
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
            justifyContent: "center",
            gap: "100px",
          }}
          id="tel"
        >
          <Title
            $isMobile={isMobile}
            style={{
              marginBottom: isMobile ? "30px" : "49px",
              marginTop: "0",
              marginBottom: "auto",
            }}
          >
            {directionLang.tel?.title[langValue]}
          </Title>
          <div>
            <Comment $isDesktop={isDesktop}>
              {langValue === "KR"
                ? "이메일로 먼저 문의해주시면 감사하겠습니다."
                : "We would appreciate if you could inquire by email first."}
            </Comment>

            <div
              style={{
                width: isMobile || isTablet ? "100%" : "1040px",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignContent: isDesktop ? "" : "center",
                flexWrap: "wrap",
                gap: isMobile ? "30px" : "40px",
                justifyContent: isDesktop ? "left" : "center",
              }}
            >
              <DepartmentContact
                title={directionLang.tel?.management[langValue]}
                tel="031-525-3803"
                email="lumir@lumir.space"
              />
              <DepartmentContact
                title={directionLang.tel?.ir[langValue]}
                tel="031-525-3808"
                email="lee.seoyeon@lumir.space"
              />
              <DepartmentContact
                title={directionLang.tel?.personnel[langValue]}
                tel="031-525-3800"
                email="lumir@lumir.space"
              />
              <DepartmentContact
                title={directionLang.tel?.overseas[langValue]}
                tel="031-525-3809"
                email="jung.jaeil@lumir.space"
              />
              <DepartmentContact
                title={directionLang.tel?.technology[langValue]}
                tel="031-525-3833"
                email="woo.eunjin@lumir.space"
              />
            </div>
          </div>
        </SectionWrapper>
      </PageWrapper>
    </>
  );
};

export default Direction;

const Comment = styled(PretendardText)`
  //styleName: b2_20_medium;
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.01em;
  text-align: ${({ $isDesktop }) => ($isDesktop ? "left" : "center")};
  color: #5a636a;
  margin-bottom: 4.2rem;
`;

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  align-items: center;
  padding: ${(props) =>
    props.$isMobile ? "80px 0" : props.$isTablet ? "80px 70px" : "120px 0"};
`;

const Title = styled(PretendardText)`
  color: #262c31;
  font-size: ${(props) =>
    props.$isMobile || props.$isTablet ? "18px" : "24px"};
  font-weight: 600;
  line-height: 145%;
  letter-spacing: ${(props) =>
    props.$isMobile || props.$isTablet ? "-0.18px" : "-0.24px"};
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: ${(props) =>
    props.$isMobile || props.$isTablet ? "column" : "row"};
  gap: ${(props) => (props.$isMobile ? "10px" : "")};
`;

const InfoTitle = styled(PretendardText)`
  color: #262c31;
  font-size: ${(props) =>
    props.$isMobile || props.$isTablet ? "15px" : "20px"};
  font-weight: 600;
  line-height: 140%;
  letter-spacing: ${(props) =>
    props.$isMobile || props.$isTablet ? "" : "-0.2px"};
  width: 160px;
`;

const InfoContent = styled(PretendardText)`
  color: #5a636a;
  font-size: ${(props) =>
    props.$isMobile || props.$isTablet ? "15px" : "20px"};
  font-weight: ${(props) => (props.$isMobile || props.$isTablet ? 400 : 500)};
  line-height: 140%;
  letter-spacing: ${(props) =>
    props.$isMobile || props.$isTablet ? "" : "-0.2px"};
  white-space: pre-wrap;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #d7dce2;
`;
