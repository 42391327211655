import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { useRecoilValue } from "recoil";

import BorderBottomInput from "../../components/atoms/input/borderBottomInput";
import PretendardText from "../../components/atoms/text/pretendardText";
import Pagination from "../../components/organisms/pagination";
import MainSection from "../../components/templates/mainSection";
import MiddleNavBar from "./../../components/templates/middleNavBar";
import ArticleCard from "../../components/templates/articleCard";

import useDisplay from "../../hooks/useDisplay";

import ArticleApi from "../../api/articleApi";
import { route } from "../../router/route";
import { langState } from "../../store/langState";
import { mainBannerLang } from "../../lang/lang";
import { lumirNewsLang } from "../../lang/relation/lumirNewsLang";
import { calculatePagination } from "../../lang/renewal/newsArticleLang";

const NewArticle = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();

  const langValue = useRecoilValue(langState);

  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = useParams();

  const [keyword, setKeyword] = useState("");
  const [totalCtn, setTotalCtn] = useState(0);
  const [articleList, setArticleList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 9, // 한 페이지에 표시될 게시글 수
    rangeSize: 3, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");

    getArticleAPI();
  }, [location, langValue]);

  const getArticleAPI = async () => {
    try {
      const data = {
        pageNo: page,
        pageSize: pagination.size,
        keyword: searchParams.get("keyword"),
        language: langValue,
      };

      // const response = (await ArticleApi.GetList(data)).data.data;
      const response = calculatePagination(data);

      setTotalCtn(response.totalCount);
      setPagination({
        ...pagination,
        totalCount: response.totalCount,
        totalPages: response.totalPage,
        first: response.isFirst,
        last: response.isLast,
      });
      setArticleList(response.items);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_new_article.png"
            : "/assets/imgs/main-section/new_article.png"
        }
        title={mainBannerLang.newsArticle.title[langValue]}
        content={mainBannerLang.newsArticle.content[langValue]}
        blackText={isMobile ? false : true}
      />
      <MiddleNavBar pathname={pathname} />

      <div
        style={{
          padding: isMobile ? "0px 20px" : isTablet ? "50px 70px" : "60px 0px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: 1400,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <div
                style={{
                  width: "100%",
                  height: 48,
                  position: "relative",
                }}
              >
                <BorderBottomInput
                  type="text"
                  placeholder="Search"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`${route.newArticle}/1?keyword=${keyword}`);
                    }
                  }}
                />
                <img
                  onClick={() => {
                    navigate(`${route.newArticle}/1?keyword=${keyword}`);
                  }}
                  style={{
                    position: "absolute",
                    right: -6,
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                    height: 24,
                    width: 24,
                  }}
                  src="/assets/icons/search_02.svg"
                />
              </div> */}

              <PretendardText
                style={{
                  color: "#778088",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "18.9px",
                  marginTop: 40,
                }}
              >
                {langValue === "KR" ? "총" : "Total"} {totalCtn}{" "}
                {langValue === "KR" ? "건" : ""}
              </PretendardText>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  color: "#778088",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "23.2px",
                }}
              >
                {langValue === "KR" ? "총" : "Total"} {totalCtn}{" "}
                {langValue === "KR" ? "건" : ""}
              </PretendardText>
              {/* <div
                style={{
                  width: isTablet ? 340 : 440,
                  height: 48,
                  position: "relative",
                }}
              >
                <BorderBottomInput
                  type="text"
                  placeholder="Search"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`${route.newArticle}/1?keyword=${keyword}`);
                    }
                  }}
                />
                <img
                  onClick={() => {
                    navigate(`${route.newArticle}/1?keyword=${keyword}`);
                  }}
                  style={{
                    position: "absolute",
                    right: -6,
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                    height: 24,
                    width: 24,
                  }}
                  src="/assets/icons/search_02.svg"
                />
              </div> */}
            </div>
          )}

          {articleList?.length > 0 ? (
            <>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: isTablet ? "space-between" : "flex-start",
                  flexWrap: "wrap",
                  gap: isMobile || isTablet ? "30px 0px" : "50px 40px",
                }}
              >
                {articleList.map((item, index) => {
                  return (
                    <ArticleCard
                      key={index}
                      src={item.fileUrl}
                      author={item.mediaCompany}
                      date={moment.utc(item.createdAt).format("YYYY.MM.DD")}
                      title={item.title}
                      link={item.link}
                    />
                  );
                })}
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: isMobile || isTablet ? 40 : 60,
                }}
              >
                <Pagination
                  route={route.newArticle}
                  queryString={`keyword=${keyword}`}
                  currentPage={page}
                  totalPages={pagination.totalPages}
                  rangeSize={pagination.rangeSize}
                  first={pagination.first}
                  last={pagination.last}
                />
              </div>
            </>
          ) : (
            <PretendardText
              style={{
                color: "#ADB4BA",
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "21px",
                marginTop: isMobile ? 60 : isTablet ? 80 : 120,
                textAlign: "center",
              }}
            >
              {searchParams.get("keyword") === "" ||
              searchParams.get("keyword") == null
                ? lumirNewsLang.empty[langValue]
                : lumirNewsLang.searchEmpty[langValue]}
            </PretendardText>
          )}
        </div>
      </div>
    </>
  );
};

export default NewArticle;
