export const introduceLang = {
  section1: {
    title: {
      KR: "국가 위성사업 분야에서\n끊임없이 탐구하는 루미르입니다.",
      EN: "Lumir is constantly exploring in the field of national satellite business.",
    },
    content: {
      KR: "루미르는 다수의 실용급 위성 및 소형위성 사업 참여를 통해 검증된 인공위성 시스템 기술을 통해우주 개발 및위성 사업 분야에서 독보적인 성장을 이루고 있습니다.",
      EN: "Lumir is achieving unrivaled growth in space development and the satellite business through its proven satellite system technology, gained through participation in numerous practical and small satellite projects.",
    },
    performance01: {
      content: {
        KR: "설립일",
        EN: "Date of Establishment",
      },
    },
    performance02: {
      title: {
        KR: "249억+",
        EN: "24.9 billion +",
      },
      content: {
        KR: "정부 R&D 과제 수주 금액",
        EN: "Government R&D Project Awarded Amount",
      },
    },
    performance03: {
      content: {
        KR: "직원 수",
        EN: "Number of Employees",
      },
    },
    performance04: {
      content: {
        KR: "국가 인공위성 개발 수주",
        EN: "National Satellite Development Awarded",
      },
    },
  },
  overview: {
    ceoTitle: {
      KR: "대표이사",
      EN: "CEO",
    },
    ceoValue: {
      KR: "남명용",
      EN: "Nam Myeong-yong",
    },
    BusinessTitle: {
      KR: "업종",
      EN: "Business Sector",
    },
    BusinessValue: {
      KR: "항공기용 부품 제조업",
      EN: "Aircraft parts manufacturing industry",
    },
    MajorBusinessTitle: {
      KR: "주요사업",
      EN: "Major Business",
    },
    MajorBusinessValue: {
      KR: "항공기,우주선 제조/항공우주부품,시스템 도소매, 수출입,무역/소프트웨어 개발,공급",
      EN: "Aircraft, spacecraft manufacturing/aerospace parts, system wholesale/retail, import/export, trade/software development, supply",
    },
    HomepageTitle: {
      KR: "홈페이지",
      EN: "Homepage",
    },
  },
  section2: {
    title: {
      KR: "Harmonia et Differentia\n화합하고 조화를 이루며 새로운 도전을 시도합니다.",
      EN: "Harmonia et Differentia\nWe strive for new challenges in harmony and unity.",
    },
    content01: {
      KR: "루미르는 초소형 인공위성 개발 및 영상 데이터 제공의 선두주자로서\n대한민국 No.1 우주 유니콘 기업으로 성장하고 있습니다.",
      EN: "Lumir is a leader in developing ultra-small satellites and providing image data, and it is growing into Korea's No. 1 space unicorn company.",
    },
    content02: {
      KR: "다른 생각을 가진 개인의 독창성과 자유를 인정하고 포용하며\n다양한 도전과 탐구를 통해 발전합니다.",
      EN: "Recognize and embrace the originality and freedom of individuals with different thoughts\nand develop through various challenges and exploration.",
    },
    visionTitle: {
      KR: "기업 비전",
      EN: "VISION",
    },
    visionContent: {
      KR: "초소형 인공위성 개발 및 영상 데이터 제공 선두주자\n대한민국 No.1 우주 유니콘 기업",
      EN: "Leader in developing ultra-small satellites and providing image data\nKorea's No. 1 Space Unicorn Company",
    },
    valueTitle: {
      KR: "핵심가치",
      EN: "VALUE",
    },
    valueContent: {
      KR: "화합하고 조화를 이루는 가운데\n다른 생각을 가진 개인의 독창성과 자유를 인정하고\n포용하는 열린 기업",
      EN: "An open enterprise that recognizes and embraces the originality and freedom of individuals with different ideas, while maintaining harmony and unity.",
    },
    ceoTitle: {
      KR: "겨레와 나라를 지키기 위해 노력하며\n앞으로 나아가는 루미르 입니다.",
      EN: "Lumir is working hard to protect\ncountry and nation.",
    },
    ceoContent01: {
      KR: "루미르는 2009년 설립 이래, 초고해상도 초소형 영상레이다(SAR) 장치와 영상레이다 신호 및 영상처리 소프트웨어의 개발 및 고도화에 전념해 왔습니다.",
      EN: "Since its inception in 2009, Lumir has been dedicated to the development and advancement of ultra-high resolution micro image radar (SAR) devices and image radar signal and image processing software.",
    },
    ceoContent02: {
      KR: "이 과정에서 국가가 주도하는 지구관측 인공위성 사업에서 다수의 핵심 장치에 대해 소형화, 경량화, 국산화에 성공하였고, 축적된 스페이스 헤리티지로 지구관측 영상 데이터 사업을 위한 견고한 기반을 마련하였습니다.",
      EN: "In this process, it succeeded in miniaturizing, lightening, and localizing a number of key devices in the state-led earth observation satellite business, and laid a solid foundation for the earth observation image data business with the accumulated space heritage.",
    },
    ceoContent03: {
      KR: "2023년 이러한 기술력을 인정받아 차세대중형위성 5호의 C-Band 영상레이다 시스템 전체를 수주하였으며, 아울러 누리호 3차 발사체 부탑재위성 Lumir-T1을 성공적으로 발사하여 민수부품 우주활용 기술을 실증하였습니다.",
      EN: "In recognition of this technology in 2023, it won the entire C-Band image radar system of the CAS500-5 and successfully launched the Nuri 3rd projectile sub-satellite Lumir-T1 to demonstrate space utilization technology for civil parts.",
    },
    ceoContent04: {
      KR: "루미르는 탄탄한 우주개발 경험과 기술력을 바탕으로 2030년까지 총 18기의 소형 영상레이다(SAR) 위성 발사를 통해 군집위성 시스템을 구축하고, 본격적인 지구관측 데이터 사업을 추진하여 대한민국이 우주강국으로 도약하는데 기여할 것 입니다.",
      EN: "Based on its solid space development experience and technology, Lumir will build a cluster satellite system through the launch of a total of 18 small image radar (SAR) satellites by 2030, and will contribute to Korea's leap into a space powerhouse by promoting a full- fledged Earth observation data project.",
    },
    ceoContent05: {
      KR: "국가 안와 직결되는 우주산업에 몸을 담고 있는만큼 '잘 만든' 위성으로 겨레와 나라를 지키기 위해 노력하며 앞으로 나아가겠습니다. 감사합니다.",
      EN: 'As we are involved in the space industry, which is directly related to national security, we will work hard to protect our country and fight with our "well-made" satellites. Thank you.',
    },
    ceoContent06: {
      KR: "루미르 대표이사 남 명 용",
      EN: "CEO Lumir NAM Myeon-ryong",
    },
  },
  section3: {
    title: {
      KR: "핵심 인재",
      EN: "Key Member",
    },
    subTitle: {
      KR: "끊임없이 탐구하는 루미르의 핵심 인재를 소개합니다.",
      EN: "Let me introduce the key members of Lumir, who are constantly exploring.",
    },
    keyMember: {
      ManagementSupport: {
        team: {
          KR: "경영지원부",
          EN: "Management Support Department",
        },
        name: {
          KR: "이봉은",
          EN: "Lee Bong Eun",
        },
        division: {
          KR: "경영지원부",
          EN: "General Managerial Support Division",
        },
      },
      technicalPart1: {
        team: {
          KR: "기술 1부",
          EN: "Technical Part 1",
        },
        name: {
          KR: "오대수",
          EN: "Oh Dae Soo",
        },
        division: {
          KR: "위성 디지털 기기 개발 사업부",
          EN: "Satellite Digital Device Development Division",
        },
      },
      technicalPart2: {
        team: {
          KR: "기술 2부",
          EN: "Technical Part 2",
        },
        name: {
          KR: "백인혁",
          EN: "Baek In Hyuk",
        },
        division: {
          KR: "위성 관제 소프트웨어 개발 사업부",
          EN: "Satellite Control Software Development Division",
        },
      },
      technicalPart3: {
        team: {
          KR: "기술 3부",
          EN: "Technical Part 3",
        },
        name: {
          KR: "우무현",
          EN: "WOO Moo-hyun",
        },
        division: {
          KR: "RF 안테나 개발 사업부",
          EN: "RF Antenna Development Division",
        },
      },
      technicalPart7: {
        team: {
          KR: "기술 7부",
          EN: "Technical Part 7",
        },
        name: {
          KR: "공영균",
          EN: "KONG Young-kyun",
        },
        division: {
          KR: "레이다 시스템 및 영상처리 소프트웨어 개발 사업부",
          EN: "Radar Systems and Image Processing Software Development Division",
        },
      },
    },
  },
  section4: {
    title: {
      KR: "루미르 CI",
      EN: "Lumir CI",
    },
    subTitle: {
      KR: "기업의 상징을 담아낸 루미르 CI를 소개합니다.",
      EN: "Let me introduce Lumir CI, which embodies the corporate symbols.",
    },
    content: {
      KR: "날개를 활짝 핀 삼족오의 형상은 보다 크고 넓게 성장하는 기업의 비전을 상징합니다.\n미래 감성에 맞게 재해석된 삼족오의 비주얼은 미래와 우주를 향한 루미르의 도약과 성장을 의미합니다.\n오로라 컬러의 태양, 우주, 지구는 신비한 미지의 공간을 개척하는 우주산업의 성장에 일조하는 LUMIR를 나타냅니다.",
      EN: "The image of the Samjok-oh with outstretched wings symbolizes the vision of a company growing larger and broader.\nThe visuals of the Samjok-oh, reinterpreted to align with future sensibilities, signify Lumir's leap and growth towards the future and space.\nThe aurora-colored sun, universe, and earth represent LUMIR's contribution to the growth of the space industry, pioneering mysterious unknown realms.",
    },
  },
};
