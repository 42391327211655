import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import useDisplay from "../../hooks/useDisplay";
import { mainVideo } from "../../lang/renewal/homeLang";
import { langState } from "../../store/langState";
import Constellation from "./renewal/constellation/Constellation";
import Device from "./renewal/device/Device";
import Intro from "./renewal/intro/Intro3";
import MainVideo from "./renewal/MainVideo";
import News from "./renewal/News";
import Recruit from "./renewal/Recruit";
import SardipCard from "./renewal/sardipCard/SardipCard";
import WhatWeDo from "./renewal/weDo/WhatWeDo";
import WeService from "./renewal/WeService";
import MainTemp from "./renewal/MainTemp";
import { useCookies } from "react-cookie";
import HomePopup from "../modal/homePopup";
import TransparentPopup from "./renewal/popup/TransparentPopup";
import { data } from "./datas/homePopup";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const HomeRenewal = () => {
  const { isDesktop, isMobile, isIPadLandscape } = useDisplay();

  const langValue = useRecoilValue(langState);
  const containerRef = useRef(null);
  const sectionRefs = useRef([]);
  const deviceTargetRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [popupList, setPopupList] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [cookies, setCookie] = useCookies(["PopupCookie"]);

  const getPopup = async () => {
    try {
      const popupData = data;
      setPopupList(popupData);

      const popupCookie = cookies["PopupCookie"];
      const currentDate = new Date();

      if (
        popupData.length > 0 &&
        (!popupCookie || new Date(popupCookie) < currentDate)
      ) {
        setPopupOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");

    if (savedScrollPosition !== null) {
      setIsLoading(true);
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY.toString());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    getPopup();

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleClose = () => {
    setPopupOpen(false);
    console.log("팝업이 닫혔습니다.");
  };

  const handleDontShowToday = () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 1);
    expires.setHours(0, 0, 0, 0);

    setCookie("PopupCookie", expires.toISOString(), { path: "/", expires });
    setPopupOpen(false);
    console.log("오늘 하루 동안 팝업이 표시되지 않습니다.");
  };

  const sectionComponents = useMemo(
    () => [
      <MainVideo
        key="video"
        fallbackImageSrc={mainVideo.fallbackImg}
        videoSrc={isDesktop ? mainVideo.pcVideo : mainVideo.mobileVideo}
        scrollTriggerTime={30}
        ref={(el) => (sectionRefs.current[0] = el)}
        isDesktop={isDesktop}
      />,
      // <MainTemp {...{ langValue, isDesktop, isMobile }} />,
      <Intro
        key="intro"
        ref={(el) => (sectionRefs.current[1] = el)}
        {...{ langValue, isDesktop, isMobile, isIPadLandscape }}
      />,
      <Device
        key="device"
        ref={(el) => {
          sectionRefs.current[2] = el;
          if (el && el.children[1] && el.children[1].children[0]) {
            deviceTargetRef.current = el.children[1].children[0];
          }
        }}
        {...{ langValue }}
      />,
      <WhatWeDo key="whatwedo" {...{ langValue }} />,
      <Constellation key="constellation" {...{ langValue }} />,
      <WeService key="weservice" {...{ langValue }} />,
      <SardipCard key="sardipcard" {...{ langValue }} />,
      <News key="news" {...{ langValue }} />,
      <Recruit key="recruit" {...{ langValue }} />,
    ],
    [isDesktop, langValue]
  );

  // interface PopupItem {
  //   fileUrl?: string;
  //   title?: string;
  //   description?: string;
  //   // 기타 필요한 속성들...
  // }

  return (
    <>
      {isLoading && <Loading />}
      <Container ref={containerRef}>{sectionComponents}</Container>
      {popupOpen ? (
        <>
          {/* <HomePopup popupList={popupList} setPopupOpen={setPopupOpen} /> */}
          <TransparentPopup
            contents={popupList}
            onClose={handleClose}
            onDontShowToday={handleDontShowToday}
          />
        </>
      ) : null}
    </>
  );
};

export default React.memo(HomeRenewal);

const Container = styled.div`
  scroll-behavior: smooth;
  font-size: 0.8vw;

  @media (max-width: 768px) {
    font-size: 100%;
  }

  @media (min-width: 768px) and (max-width: 1400px) {
    font-size: 110%;
  }
`;

const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;
