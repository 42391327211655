import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";

import PretendardText from "../../components/atoms/text/pretendardText";
import { route } from "../../router/route";
import useDisplay from "../../hooks/useDisplay";

const ArticleCard = ({ src, author, date, title, style, link, id }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigation = useNavigate();

  return (
    <CardWrapper
      $isMobile={isMobile}
      $isTablet={isTablet}
      style={{ ...style }}
      onClick={() => {
        if (link) {
          window.open(link, "_blank", "noopener, noreferrer");
        } else {
          navigation(`${route.lumirNewsDetail}/${id}`);
        }
      }}
      className="custom-cursor"
    >
      <img
        src={src}
        style={{
          width: "100%",
          objectFit: "cover",
          height: isMobile ? "186px" : "312px",
        }}
        alt={title}
        loading="lazy"
      />
      <MetaWrapper $isMobile={isMobile} $isTablet={isTablet}>
        <MetaText $isMobile={isMobile} $isTablet={isTablet}>
          {author}
        </MetaText>
        <MetaText
          $isMobile={isMobile}
          $isTablet={isTablet}
          style={{ fontWeight: 400 }}
        >
          {date}
        </MetaText>
      </MetaWrapper>
      <Title $isMobile={isMobile} $isTablet={isTablet}>
        {title}
      </Title>
    </CardWrapper>
  );
};

export default ArticleCard;

const CardWrapper = styled.div`
  width: ${(props) =>
    props.$isMobile ? "100%" : props.$isTablet ? "48%" : "440px"};
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* cursor: pointer; */
`;

const MetaWrapper = styled.div`
  display: flex;
  gap: ${(props) =>
    props.$isMobile ? "5.38px" : props.$isTablet ? "7px" : "10px"};
`;

const MetaText = styled(PretendardText)`
  font-size: ${(props) =>
    props.$isMobile || props.$isTablet ? "13px" : "14px"};
  font-weight: 600;
  line-height: ${(props) =>
    props.$isMobile || props.$isTablet ? "17.55px" : "18.9px"};
  color: #adb4ba;
`;

const Title = styled(PretendardText)`
  font-size: ${(props) =>
    props.$isMobile ? "15px" : props.$isTablet ? "17px" : "20px"};

  font-weight: 600;
  line-height: ${(props) =>
    props.$isMobile ? "21px" : props.$isTablet ? "26px" : "29px"};

  color: #262c31;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
